/* Status badge styles */

.badge-element {
  background-color: #ededed;
  color: #666;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
}
.badge-element.active {
  background-color: #e2f5ec !important;
  color: #198754 !important;
}
.badge-element.inactive {
  background-color: #ededed !important;
  color: #666666 !important;
}
.badge-element.invited {
  background-color: #e1e8ff !important;
  color: #3950a2 !important;
}
.badge-element.pending-invited {
  background-color: #f2e9ce !important;
  color: #f2b90c !important;
}
.badge-element.deleted {
  background-color: #f0c1c5 !important;
  color: #bb2d3b !important;
}
