.section-btn {
  background-color: #e2f6fe;
  border: 1px solid #c0dbed;
  color: #00438b;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  padding: 6px 10px;
}
.section-btn:disabled {
  background-color: #ededed !important;
  color: #b0b0b0 !important;
  border-color: #cbcbcb !important;
  cursor: not-allowed;
}
.section-delete-btn,
.modal-copy-btn {
  background-position: center center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}
.section-delete-btn {
  padding: 2px !important;
  background-image: url("../../assets/images/icons/trash.svg");
  background-size: 16px;
}
.modal-copy-btn {
  background-image: url("../../assets/images/icons/copy.svg");
  background-size: 20px;
}
.section-btn > i {
  margin-right: 6px;
}
.section-btn > i::before {
  font-size: 22px !important;
  font-weight: 700 !important;
}

.section-collapse-btn {
  background-color: transparent;
  border: none;
  margin-left: 24px;
  height: 36px;
  width: 36px;
  color: #25396f;
}
.section-collapse-btn > i::before {
  font-weight: 700 !important;
  font-size: 20px !important;
  transition: 0.3s ease;
}
.section-collapse-btn.collapsed > i::before {
  transform: rotate(180deg) !important;
}
