.custom-date-picker input {
  appearance: none !important;
  background-color: #f7f7f7;
  border: none !important;
  border-radius: 8px;
  padding: 8px 34px 8px 14px;
  max-width: 140px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-image: url("../../assets/images/icons/calendar-icon.svg");
  color: #000 !important;
}

.custom-date-picker input::placeholder {
  color: #000 !important;
  opacity: 1;
}
