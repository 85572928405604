.e-rte-toolbar .e-rte-font-color::before,
.e-rte-dropdown-popup .e-rte-font-color::before {
  content: "\e79f";
}
.e-richtexteditor
  .e-rte-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-font-color.e-icons::before {
  font-size: 13px;
}
