/* Toggle switch styles */

.toggle-switch-wrap {
  display: flex;
  align-items: center;
}
.toggle-switch-wrap .toggle-label {
  font-weight: 600;
  margin-right: 4px;
}
.toggle-switch {
  position: relative;
  width: 46px;
  height: 24px;
  border-radius: 50px;
  box-sizing: unset;
  border: 4px solid transparent;
  transition: 0.4s ease;
}

.toggle-switch:hover {
  border: 4px solid #edf1fd;
}

.toggle-switch input {
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 50px;
  box-shadow: inset 0 2px 2px rgb(0 0 0 / 20%);
  transition: 0.4s ease;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
  transition: 0.4s ease;
}

.toggle-switch input:checked + .slider {
  background-color: #6188dd;
}

.toggle-switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
