.table-action-btn {
  border: none;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
}
.table-action-btn:first-child {
  margin-left: 0;
}
.table-action-btn i {
  line-height: normal;
  font-size: 18px;
}
.table-action-btn i::before {
  display: block;
}

.table-styles thead > tr {
  background-color: #dce2eb;
}
.table-styles tbody > tr:nth-child(even) {
  background-color: #ecf2fb;
}
.table-action-btn:hover {
  color: #435ebe;
}
.table-styles tbody > tr:hover > * {
  background-color: #f3f8ff;
  box-shadow: none;
}
.table-styles tbody,
.table-styles tbody td,
.table-styles thead th {
  border: none !important;
}
.table-styles .tbl-actions-th,
.table-styles .tbl-actions-td {
  width: 200px;
  text-align: right;
}
.table-styles tbody,
.table-styles tr td {
  border: none !important;
}
.row-drag-icon {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.row-drag-icon:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.table-select-element select {
  background-image: url("../../assets/images/icons/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding-right: 30px;
}
.table-search-field-element .form-control {
  background-image: url("../../assets/images/icons/magnifier.svg");
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 16px;
  padding-left: 36px;
}
