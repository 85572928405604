.table-pagination-wrap .page-item .page-link {
  border-color: transparent !important;
  padding: 10px 16px !important;
}
.table-pagination-wrap .page-item .page-link > span:first-child::before {
  line-height: unset;
}
.table-pagination-wrap .page-item {
  margin-left: 2px;
}
.table-pagination-wrap .pagination {
  margin-bottom: 0 !important;
}
