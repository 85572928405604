:root {
  --bs-blue: #435ebe;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #435ebe;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 67, 94, 190;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 96, 112, 128;
  --bs-body-bg-rgb: 242, 247, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
  --bs-body-font-family: Nunito;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #607080;
  /* --bs-body-bg: #f2f7ff; */
  --bs-body-bg: #f8f8f8;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #25396f;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.alert {
  border: none;
}

.alert p {
  margin-bottom: 0;
}

.alert button.close {
  line-height: 0.75;
  padding: 0.75rem;
}

.alert .alert-heading,
.alert .alert-heading + p {
  margin-left: 0.4rem;
}

.alert-primary {
  background-color: #435ebe;
  color: #fff;
}

.alert-primary a {
  color: #fff;
  font-weight: 700;
}

.alert-secondary {
  background-color: #ebeef3;
  color: #383d41;
}

.alert-secondary a {
  color: #fff;
  font-weight: 700;
}

.alert-success {
  background-color: #4fbe87;
  color: #fff;
}

.alert-success a {
  color: #fff;
  font-weight: 700;
}

.alert-warning {
  background-color: #eaca4a;
  color: #fff;
}

.alert-warning a {
  color: #fff;
  font-weight: 700;
}

.alert-danger {
  background-color: #f3616d;
  color: #fff;
}

.alert-danger a {
  color: #fff;
  font-weight: 700;
}

.alert-dark {
  background-color: #454546;
  color: #fff;
}

.alert-dark a {
  color: #fff;
  font-weight: 700;
}

.alert-light {
  background-color: #f9f9f9;
  color: #818182;
}

.alert-light a {
  color: #fff;
  font-weight: 700;
}

.alert-info {
  background-color: #56b6f7;
  color: #fff;
}

.alert-info a {
  color: #fff;
  font-weight: 700;
}

.alert-light-primary {
  background-color: #ebf3ff;
}

.alert-light-secondary {
  background-color: #e6eaee;
}

.alert-light-success {
  background-color: #d2ffe8;
}

.alert-light-danger {
  background-color: #ffdede;
}

.alert-light-warning {
  background-color: #fffdd8;
}

.alert-light-info {
  background-color: #e6fdff;
}

.avatar {
  border-radius: 50%;
  display: inline-flex;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.avatar .avatar-content {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.avatar .avatar-content i,
.avatar .avatar-content svg {
  color: #fff;
  font-size: 1rem;
  height: 1rem;
}

.avatar img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.avatar .avatar-status {
  border: 1px solid #fff;
  border-radius: 50%;
  bottom: 0;
  height: 0.7rem;
  position: absolute;
  right: 0;
  width: 0.7rem;
}

.avatar.avatar-sm .avatar-content,
.avatar.avatar-sm img {
  font-size: 0.8rem;
  height: 24px;
  width: 24px;
}

.avatar.avatar-md .avatar-content,
.avatar.avatar-md img {
  font-size: 0.8rem;
  height: 32px;
  width: 32px;
}

.avatar.avatar-md2 .avatar-content,
.avatar.avatar-md2 img {
  font-size: 0.8rem;
  height: 40px;
  width: 40px;
}

.avatar.avatar-lg .avatar-content,
.avatar.avatar-lg img {
  font-size: 1.2rem;
  height: 48px;
  width: 48px;
}

.avatar.avatar-xl .avatar-content,
.avatar.avatar-xl img {
  font-size: 1.4rem;
  height: 60px;
  width: 60px;
}

.btn .badge {
  border-radius: 50%;
  margin-left: 5px;
}

.btn .badge.bg-transparent {
  background-color: hsla(0, 0%, 100%, 0.25) !important;
  color: #fff;
}

.btn i,
.btn svg {
  height: 16px;
  width: 16px;
}

.btn.icon {
  padding: 0.4rem 0.6rem;
}

.btn.icon svg {
  height: 16px;
  width: 16px;
}

.btn.icon.icon-left svg {
  margin-right: 3px;
}

.btn.icon.icon-right svg {
  margin-left: 3px;
}

.btn.btn-outline-white {
  border-color: #fff;
  color: #fff;
}

.btn.btn-outline-white:hover {
  background-color: #fff;
  color: #333;
}

.btn.btn-light-primary {
  background-color: #ebf3ff;
  color: #002152;
}

.btn.btn-light-secondary {
  background-color: #e6eaee;
  color: #181e24;
}

.btn.btn-light-success {
  background-color: #d2ffe8;
  color: #00391c;
}

.btn.btn-light-danger {
  background-color: #ffdede;
  color: #450000;
}

.btn.btn-light-warning {
  background-color: #fffdd8;
  color: #3f3c00;
}

.btn.btn-light-info {
  background-color: #e6fdff;
  color: #00474d;
}

.btn.btn-danger,
.btn.btn-info,
.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-success,
.btn.btn-warning {
  color: #fff;
}

.btn.btn-light {
  color: #607080;
}

.btn.btn-dark {
  color: #fff;
}

.btn-block {
  width: 100%;
}

.btn-group:not(.dropdown) .btn:not([class*="btn-"]) {
  border: 1px solid #dfe3e7;
}

.btn-group > .btn {
  border-radius: 0.267rem;
}

.buttons .btn {
  margin: 0 10px 10px 0;
}

.breadcrumb.breadcrumb-right {
  justify-content: flex-end;
  margin-top: 1rem;
}

.breadcrumb.breadcrumb-center {
  justify-content: center;
  margin-top: 1rem;
}

.carousel-inner {
  border-radius: 0.7rem;
}

.carousel-caption h5 {
  color: #fff;
}

.card {
  border: none;
  margin-bottom: 2.2rem;
}

.card.card-statistic {
  background: linear-gradient(180deg, #25a6f1, #54b9ff);
  box-shadow: 1px 2px 5px rgba(47, 170, 244, 0.5);
}

.card.card-statistic .card-title {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: uppercase;
}

.card.card-statistic .card-right p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.card.card-statistic .card-right span.green {
  color: #6fff6f;
}

.card.card-statistic .card-right span.red {
  color: #ff7979;
}

.card.card-statistic .chart-wrapper {
  height: 100px;
}

.card .card-header {
  border: none;
}

.card .card-header h4 {
  font-size: 1.2rem;
  font-weight: 700;
}

.card .card-header ~ .card-body {
  padding-top: 0;
}

.card .card-content {
  position: relative;
}

.card .card-title {
  font-size: 1.2rem;
}

.card .card-body {
  padding: 1.5rem;
}

.card .card-heading {
  color: #555;
  font-size: 1.5rem;
}

.card .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.card .card-img-overlay p {
  color: #eee;
}

.card .card-img-overlay .card-title {
  color: #fff;
}

.pricing .card {
  border-right: 1px solid #e9ecef;
  box-shadow: none;
  box-shadow: 0 10px 10px #e9ecef;
  margin-bottom: 0.5rem;
}

.pricing h1 {
  font-size: 4rem;
  margin-bottom: 3rem;
  text-align: center;
}

.pricing .card-header .card-title {
  font-size: 2rem !important;
  margin-bottom: 0;
}

.pricing .card-header p {
  font-size: 0.8rem;
}

.pricing ul li {
  list-style: none;
  margin-bottom: 0.5rem;
}

.pricing ul li i,
.pricing ul li svg {
  color: #198754;
  font-size: 1rem;
  margin-right: 7px;
  width: 1rem;
}

.pricing .card-highlighted {
  background-color: #435ebe;
  padding-bottom: 20px;
  padding-top: 20px;
}

.pricing .card-highlighted .card-body,
.pricing .card-highlighted .card-header {
  background-color: #435ebe;
  color: #fff;
}

.pricing .card-highlighted ul li {
  color: #fff;
}

.pricing .card-highlighted ul li i,
.pricing .card-highlighted ul li svg {
  color: #479f76;
}

.pricing .card-highlighted .card-footer {
  background-color: #435ebe;
}

.pricing .card-highlighted .card-title {
  color: #fff;
  font-size: 1.8rem;
}

.divider {
  display: block;
  margin: 1rem 0;
  overflow: hidden;
  text-align: center;
}

.divider .divider-text {
  background-color: #fff;
  display: inline-block;
  padding: 0 1rem;
  position: relative;
}

.divider .divider-text:after,
.divider .divider-text:before {
  border-top: 1px solid #dfe3e7;
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:after {
  left: 100%;
}

.divider.divider-left .divider-text {
  float: left;
  left: 0;
  padding-left: 0;
}

.divider.divider-left-center .divider-text {
  left: -25%;
}

.divider.divider-right-center .divider-text {
  left: 25%;
}

.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}

.dropdown-toggle:after {
  color: #fff;
}

.dropdown-menu-large {
  min-width: 16rem;
}

.dropdown-menu {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
}

.dropdown-item {
  transition: all 0.5s;
}

.dropdown-menu-end.show {
  right: 0;
  top: 100%;
}

.dropdown .avatar {
  margin-right: 0.6rem;
}

.user-dropdown-name,
.user-dropdown-status {
  margin: 0;
}

.form-group {
  margin-bottom: 0.7rem;
}

.form-group label {
  color: rgba(35, 28, 99, 0.7);
  font-weight: 600;
}

.form-group small {
  font-size: 0.7rem;
}

.form-group.with-title {
  position: relative;
}

.form-group.with-title label {
  background-color: #e9ecef;
  font-size: 0.6rem;
  left: 0;
  padding: 5px;
  position: absolute;
  top: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.form-group.with-title .form-control,
.form-group.with-title dataTable-input {
  padding-top: 2rem;
}

.form-group.with-title .form-control:focus ~ label,
.form-group.with-title dataTable-input:focus ~ label {
  border-left: 1px solid #435ebe;
  border-right: 1px solid #435ebe;
  border-top: 1px solid #435ebe;
}

.form-group[class*="has-icon-"].has-icon-left .form-control {
  padding-left: 2.5rem;
}

.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
  left: 0;
}

.form-group[class*="has-icon-"].has-icon-right .form-control {
  padding-right: 2.5rem;
}

.form-group[class*="has-icon-"].has-icon-right .form-control-icon {
  right: 0;
}

.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon i,
.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon svg {
  color: #5a8dee;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl {
  padding-left: 3rem;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl ~ .form-control-icon i {
  font-size: 1.6rem;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl ~ .form-control-icon i:before {
  color: #a6a8aa;
}

.form-group[class*="has-icon-"] .form-control-icon {
  padding: 0 0.6rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-group[class*="has-icon-"] .form-control-icon i,
.form-group[class*="has-icon-"] .form-control-icon svg {
  color: #adb5bd;
  font-size: 1.2rem;
  width: 1.2rem;
}

.form-group[class*="has-icon-"] .form-control-icon i:before,
.form-group[class*="has-icon-"] .form-control-icon svg:before {
  vertical-align: sub;
}

.form-control.form-control-xl {
  font-size: 1.2rem;
  padding: 0.85rem 1rem;
}

.form-check .form-check-input[class*="bg-"] {
  border: 0;
}

.form-check .form-check-input:focus {
  box-shadow: none;
}

.form-check .form-check-input.form-check-primary {
  background-color: #435ebe;
  border-color: #435ebe;
}

.form-check .form-check-input.form-check-primary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-primary.form-check-glow {
  box-shadow: 0 0 5px #697ecb;
}

.form-check .form-check-input.form-check-primary.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.form-check .form-check-input.form-check-secondary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-secondary.form-check-glow {
  box-shadow: 0 0 5px #868e96;
}

.form-check .form-check-input.form-check-secondary.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-success {
  background-color: #198754;
  border-color: #198754;
}

.form-check .form-check-input.form-check-success:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-success.form-check-glow {
  box-shadow: 0 0 5px #21b26f;
}

.form-check .form-check-input.form-check-success.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-info {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.form-check .form-check-input.form-check-info:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-info.form-check-glow {
  box-shadow: 0 0 5px #3cd5f4;
}

.form-check .form-check-input.form-check-info.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.form-check .form-check-input.form-check-warning:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-warning.form-check-glow {
  box-shadow: 0 0 5px #ffce3a;
}

.form-check .form-check-input.form-check-warning.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.form-check .form-check-input.form-check-danger:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-danger.form-check-glow {
  box-shadow: 0 0 5px #e4606d;
}

.form-check .form-check-input.form-check-danger.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-light {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.form-check .form-check-input.form-check-light:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-light.form-check-glow {
  box-shadow: 0 0 5px #fff;
}

.form-check .form-check-input.form-check-light.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check .form-check-input.form-check-dark {
  background-color: #212529;
  border-color: #212529;
}

.form-check .form-check-input.form-check-dark:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.form-check .form-check-input.form-check-dark.form-check-glow {
  box-shadow: 0 0 5px #383f45;
}

.form-check .form-check-input.form-check-dark.form-check-glow:not(:checked) {
  box-shadow: none;
}

.form-check.form-check-sm .form-check-input {
  height: 0.9rem;
  margin-top: 0.3em;
  width: 0.9rem;
}

.form-check.form-check-sm label {
  font-size: 0.7rem;
}

.form-check.form-check-lg .form-check-input {
  height: 1.5rem;
  margin-top: 0.3em;
  width: 1.5rem;
}

.form-check.form-check-lg label {
  font-size: 1rem;
}

.form-check.form-check-primary .form-check-input {
  background-color: #435ebe;
  border-color: #435ebe;
}

.form-check.form-check-secondary .form-check-input {
  background-color: #6c757d;
  border-color: #6c757d;
}

.form-check.form-check-success .form-check-input {
  background-color: #198754;
  border-color: #198754;
}

.form-check.form-check-info .form-check-input {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.form-check.form-check-warning .form-check-input {
  background-color: #ffc107;
  border-color: #ffc107;
}

.form-check.form-check-danger .form-check-input {
  background-color: #dc3545;
  border-color: #dc3545;
}

.form-check.form-check-light .form-check-input {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.form-check.form-check-dark .form-check-input {
  background-color: #212529;
  border-color: #212529;
}

.dataTable-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dfe3e7;
  border-radius: 0.25rem;
  color: #555252;
  font-size: 0.9025rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: calc(1.5em + 0.934rem + 2px);
  padding: 0.467rem 0.6rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dataTable-input:focus {
  background-color: #fff;
  border-color: #5a8dee;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  color: #555252;
  outline: 0;
}

.modal .modal-content {
  border: none;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

.modal .modal-full {
  max-width: 94%;
}

.modal .white {
  color: #fff;
}

.modal .modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.modal .modal-header .modal-title {
  font-size: 1.1rem;
}

.modal .modal-header .close {
  background: none;
  border: none;
  border-radius: 50%;
  padding: 7px 10px;
}

.modal .modal-header .close:hover {
  background: #dee2e6;
}

.modal .modal-header i,
.modal .modal-header svg {
  font-size: 12px;
  height: 12px;
  width: 12px;
}

.modal .modal-footer {
  padding: 1rem;
}

.modal.modal-borderless .modal-header {
  border-bottom: 0;
}

.modal.modal-borderless .modal-footer {
  border-top: 0;
}

#sidebar.active .sidebar-wrapper {
  left: 0;
}

#sidebar:not(.active) .sidebar-wrapper {
  left: -300px;
}

#sidebar:not(.active) ~ #main {
  margin-left: 0;
}

.sidebar-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transition: left 0.5s ease-out;
  width: 300px;
  z-index: 10;
}

.sidebar-wrapper .sidebar-header {
  font-size: 2rem;
  font-weight: 700;
  padding: 2rem 2rem 1rem;
}

.sidebar-wrapper .sidebar-header img {
  height: 1.2rem;
}

.sidebar-wrapper .sidebar-toggler.x {
  display: none;
  position: absolute;
  right: 0;
  top: 0.5rem;
}

.sidebar-wrapper .menu {
  font-weight: 600;
  margin-top: 2rem;
  padding: 0 2rem;
}

.sidebar-wrapper .menu .sidebar-title {
  color: #25396f;
  font-size: 1rem;
  font-weight: 600;
  list-style: none;
  margin: 1.5rem 0 1rem;
  padding: 0 1rem;
}

.sidebar-wrapper .menu .sidebar-link {
  align-items: center;
  border-radius: 0.5rem;
  color: #25396f;
  display: block;
  display: flex;
  font-size: 1rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition: all 0.5s;
}

.sidebar-wrapper .menu .sidebar-link i,
.sidebar-wrapper .menu .sidebar-link svg {
  color: #7c8db5;
}

.sidebar-wrapper .menu .sidebar-link span {
  margin-left: 1rem;
}

.sidebar-wrapper .menu .sidebar-link:hover {
  background-color: #f0f1f5;
}

.sidebar-wrapper .menu .sidebar-item {
  list-style: none;
  margin-top: 0.5rem;
  position: relative;
}

.sidebar-wrapper .menu .sidebar-item.has-sub .sidebar-link:after {
  color: #ccc;
  content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><path style="fill:none;stroke:gray;stroke-width:1" d="m6 9 6 6 6-6"/></svg>');
  display: block;
  position: absolute;
  right: 15px;
  top: 12px;
}

.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link {
  background-color: #435ebe;
}

.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link span {
  color: #fff;
}

.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link i,
.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link svg {
  fill: #fff;
  color: #fff;
}

.sidebar-wrapper .menu .sidebar-item.active > .sidebar-link.has-sub:after {
  content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><path style="fill:none;stroke:%23fff;stroke-width:1" d="m6 9 6 6 6-6"/></svg>');
}

.sidebar-wrapper .menu .submenu {
  display: none;
  list-style: none;
  overflow: hidden;
  transition: max-height 2s cubic-bezier(0, 0.55, 0.45, 1);
}

.sidebar-wrapper .menu .submenu.active {
  display: block;
  max-height: 999px;
}

.sidebar-wrapper .menu .submenu .submenu-item.active {
  position: relative;
}

.sidebar-wrapper .menu .submenu .submenu-item.active > a {
  color: #435ebe;
  font-weight: 700;
}

.sidebar-wrapper .menu .submenu .submenu-item a {
  color: #25396f;
  display: block;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0.7rem 2rem;
  transition: all 0.3s;
}

.sidebar-wrapper .menu .submenu .submenu-item a:hover {
  margin-left: 0.3rem;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

@media screen and (min-width: 1200px) {
  .sidebar-backdrop {
    display: none !important;
    opacity: 0 !important;
  }
}

@media screen and (max-width: 1199px) {
  .sidebar-wrapper {
    left: -300px;
    position: absolute;
  }

  .sidebar-wrapper .sidebar-toggler.x {
    display: block;
  }
}

.nav-pills .nav-link.active {
  box-shadow: 0 2px 10px rgba(67, 94, 190, 0.5);
}

.nav-tabs,
.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link:hover {
  border: none;
  text-shadow: 0 0 2px rgba(67, 94, 190, 0.3);
}

.nav-tabs .nav-link.active {
  border: none;
  color: #435ebe;
  position: relative;
}

.nav-tabs .nav-link.active:after {
  background-color: #435ebe;
  bottom: 0;
  box-shadow: 0 2px 5px rgba(67, 94, 190, 0.5);
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

.navbar-fixed {
  background-color: #fff;
  position: fixed;
}

.navbar {
  height: 90px;
  padding: 1.5rem;
}

.navbar .navbar-brand img {
  height: 1.5rem;
}

.navbar .user-menu img {
  height: 39px;
  width: 39px;
}

.navbar.navbar-header li {
  align-items: center;
  display: flex;
}

.navbar.navbar-header li.nav-icon {
  margin-right: 0.4rem;
}

.navbar.navbar-header li.nav-icon .nav-link {
  border-radius: 50%;
  display: block;
  padding: 0.4rem;
}

.navbar.navbar-header li.nav-icon .nav-link:hover {
  background-color: #e9ecef;
}

.navbar.navbar-header .dropdown > a {
  color: #6c757d;
  font-weight: 600;
}

.navbar.navbar-header .dropdown > a svg {
  height: 24px;
  width: 24px;
}

.navbar.navbar-header .dropdown > a:after {
  display: none;
}

.layout-horizontal .header-top {
  background-color: #fff;
  padding: 1.1rem;
}

.layout-horizontal .header-top .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.layout-horizontal .header-top .burger-btn i {
  display: inline-block;
  height: 20px;
}

.layout-horizontal .header-top .logo img {
  height: 20px;
}

.layout-horizontal .header-top-right {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.layout-horizontal .main-navbar {
  background-color: #435ebe;
  padding: 1rem;
}

.layout-horizontal .main-navbar ul {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.layout-horizontal .main-navbar ul .menu-link {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.4rem 0;
  position: relative;
}

.layout-horizontal .main-navbar ul .menu-link span {
  height: 20px;
}

.layout-horizontal .main-navbar ul > .menu-item {
  position: relative;
}

.layout-horizontal .main-navbar ul > .menu-item .menu-link {
  color: #dee2e6;
}

.layout-horizontal .main-navbar ul > .menu-item.has-sub .menu-link {
  padding-right: 1.3rem;
}

.layout-horizontal .main-navbar ul > .menu-item.has-sub .menu-link:after {
  color: #fff;
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23ccc' opacity='.7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='m6 9 6 6 6-6'/%3E%3C/svg%3E");
  display: block;
  position: absolute;
  right: 0;
  top: 7px;
}

.layout-horizontal .main-navbar ul > .menu-item:hover .menu-link {
  color: #fff;
}

.layout-horizontal .main-navbar ul > .menu-item:hover .submenu {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.layout-horizontal .main-navbar .submenu {
  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 5px 20px hsla(0, 0%, 39%, 0.1);
  opacity: 0;
  position: absolute;
  top: 125%;
  transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);
  visibility: hidden;
  z-index: 999;
}

.layout-horizontal .main-navbar .submenu .submenu-group-wrapper {
  position: relative;
}

.layout-horizontal .main-navbar .submenu .submenu-group {
  display: table-cell;
  flex-wrap: wrap;
  max-height: 200px;
  min-width: 200px;
  padding: 0.5rem 0.3rem 0.3rem 0.5rem;
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item,
.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item.has-sub .submenu-link {
  position: relative;
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item.has-sub .submenu-link:after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23888' class='bi bi-chevron-right'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-40%);
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item a {
  color: #6c757d;
  display: block;
  padding: 0.6rem 2rem 0.6rem 0.6rem;
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item a:hover {
  color: #343a40;
}

.layout-horizontal .main-navbar .submenu .submenu-group .submenu-item:hover .subsubmenu {
  opacity: 1;
  top: 0;
  visibility: visible;
}

.layout-horizontal .main-navbar .subsubmenu {
  background-color: #fff;
  border-radius: 0.2rem;
  border-radius: 0.3rem;
  box-shadow: 0 5px 20px hsla(0, 0%, 39%, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0;
  left: 100%;
  min-width: 200px;
  opacity: 0;
  padding: 0.5rem;
  position: absolute;
  top: 125%;
  top: 1rem;
  transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);
  visibility: hidden;
  z-index: 999;
}

@media screen and (max-width: 1199px) {
  .layout-horizontal .main-navbar {
    background-color: #f5f7fc;
    display: none;
    overflow: hidden;
    padding: 1rem;
  }

  .layout-horizontal .main-navbar.active {
    max-height: none;
  }

  .layout-horizontal .main-navbar ul {
    flex-direction: column;
    gap: 0;
  }

  .layout-horizontal .main-navbar ul .menu-item.has-sub .menu-link:after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23888' opacity='.7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='m6 9 6 6 6-6'/%3E%3C/svg%3E") !important;
    top: unset;
  }

  .layout-horizontal .main-navbar ul .menu-link {
    color: #6c757d !important;
    padding: 1rem 0;
  }

  .layout-horizontal .main-navbar .submenu {
    background-color: unset;
    box-shadow: none;
    display: none;
    opacity: 1;
    padding: 0 1rem;
    position: static;
    transition: all 0.2s;
    visibility: visible !important;
  }

  .layout-horizontal .main-navbar .submenu .submenu-group-wrapper {
    display: flex;
    flex-direction: column;
    max-height: unset;
  }

  .layout-horizontal .main-navbar .submenu .submenu-group {
    max-height: unset;
    min-width: unset;
    padding: 0;
    width: 100%;
  }

  .layout-horizontal .main-navbar .submenu.active {
    display: block;
  }

  .layout-horizontal .main-navbar .subsubmenu {
    background-color: unset;
    box-shadow: none;
    display: none;
    opacity: 1;
    padding: 1rem;
    position: static;
    visibility: visible !important;
  }

  .layout-horizontal .main-navbar .subsubmenu.active {
    display: block;
  }
}

.pagination.pagination-primary .page-item.active .page-link {
  background-color: #435ebe;
  border-color: #435ebe;
  box-shadow: 0 2px 5px rgba(67, 94, 190, 0.3);
}

.pagination.pagination-secondary .page-item.active .page-link {
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 2px 5px hsla(208, 7%, 46%, 0.3);
}

.pagination.pagination-success .page-item.active .page-link {
  background-color: #198754;
  border-color: #198754;
  box-shadow: 0 2px 5px rgba(25, 135, 84, 0.3);
}

.pagination.pagination-info .page-item.active .page-link {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: 0 2px 5px rgba(13, 202, 240, 0.3);
}

.pagination.pagination-warning .page-item.active .page-link {
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 2px 5px rgba(255, 193, 7, 0.3);
}

.pagination.pagination-danger .page-item.active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 2px 5px rgba(220, 53, 69, 0.3);
}

.pagination.pagination-light .page-item.active .page-link {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(248, 249, 250, 0.3);
}

.pagination.pagination-dark .page-item.active .page-link {
  background-color: #212529;
  border-color: #212529;
  box-shadow: 0 2px 5px rgba(33, 37, 41, 0.3);
}

.page-item:not(.active) .page-link:hover {
  color: #000;
}

.page-item i,
.page-item svg {
  font-size: 13px;
  height: 13px;
  width: 13px;
}

.page-item .page-link {
  font-size: 0.875rem;
}

.page-item .page-link:focus {
  box-shadow: none;
}

.page-item:first-child {
  margin-right: 0.4rem;
}

.page-item:last-child {
  margin-left: 0.4rem;
}

.dataTable-table td,
.dataTable-table thead th,
.table td,
.table thead th {
  vertical-align: middle;
}

.dataTable-table:not(.table-borderless) thead th,
.table:not(.table-borderless) thead th {
  border-bottom: 1px solid #dedede;
}

.table-md.dataTable-table tr td,
.table-md.dataTable-table tr th,
.table-sm.dataTable-table tr td,
.table-sm.dataTable-table tr th,
.table.table-md tr td,
.table.table-md tr th,
.table.table-sm tr td,
.table.table-sm tr th {
  padding: 1rem;
}

.table-lg.dataTable-table tr td,
.table-lg.dataTable-table tr th,
.table.table-lg tr td,
.table.table-lg tr th {
  padding: 1.3rem;
}

.dataTable-container {
  overflow-x: auto;
}

.progress.progress-primary {
  overflow: visible;
}

.progress.progress-primary .progress-bar {
  background-color: #435ebe;
  border-radius: 0.25rem;
}

.progress.progress-secondary {
  overflow: visible;
}

.progress.progress-secondary .progress-bar {
  background-color: #6c757d;
  border-radius: 0.25rem;
}

.progress.progress-success {
  overflow: visible;
}

.progress.progress-success .progress-bar {
  background-color: #198754;
  border-radius: 0.25rem;
}

.progress.progress-info {
  overflow: visible;
}

.progress.progress-info .progress-bar {
  background-color: #0dcaf0;
  border-radius: 0.25rem;
}

.progress.progress-warning {
  overflow: visible;
}

.progress.progress-warning .progress-bar {
  background-color: #ffc107;
  border-radius: 0.25rem;
}

.progress.progress-danger {
  overflow: visible;
}

.progress.progress-danger .progress-bar {
  background-color: #dc3545;
  border-radius: 0.25rem;
}

.progress.progress-light {
  overflow: visible;
}

.progress.progress-light .progress-bar {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

.progress.progress-dark {
  overflow: visible;
}

.progress.progress-dark .progress-bar {
  background-color: #212529;
  border-radius: 0.25rem;
}

.progress.progress-sm {
  height: 0.4rem;
}

.progress.progress-lg {
  height: 1.5rem;
}

.progress .progress-bar {
  overflow: visible;
  position: relative;
}

.progress .progress-bar.progress-label:before {
  color: #495057;
  content: attr(aria-valuenow) "%";
  font-size: 0.8rem;
  position: absolute;
  right: 0;
  top: -1.3rem;
}

.bi {
  height: 1rem;
  width: 1rem;
}

.bi.bi-middle:before {
  vertical-align: middle;
}

.bi.bi-sub:before {
  vertical-align: sub;
}

.stats-icon {
  align-items: center;
  background-color: #000;
  border-radius: 0.5rem;
  display: flex;
  float: right;
  height: 3rem;
  justify-content: center;
  width: 3rem;
}

.stats-icon i {
  color: #fff;
  font-size: 1.7rem;
}

.stats-icon.purple {
  background-color: #9694ff;
}

.stats-icon.blue {
  background-color: #57caeb;
}

.stats-icon.red {
  background-color: #ff7976;
}

.stats-icon.green {
  background-color: #5ddab4;
}

@media (max-width: 767px) {
  .stats-icon {
    float: left;
    margin-bottom: 0.4rem;
  }
}

.burger-btn {
  display: none;
}

#main {
  margin-left: 300px;
  padding: 2rem;
}

@media screen and (max-width: 1199px) {
  #main {
    margin-left: 0;
  }
}

#main.layout-navbar {
  padding: 0;
}

#main.layout-horizontal {
  margin: 0;
  padding: 0;
}

#main #main-content {
  padding: 2rem;
}

.page-heading {
  margin: 0 0 2rem;
}

.page-heading h3 {
  font-weight: 700;
}

.page-title-headings {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.page-title-headings h3 {
  margin-bottom: 0;
  margin-right: 1rem;
}

.page-title-headings .breadcrumb {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.mt-10 {
  margin-top: 3rem;
}

.mb-10,
.my-10 {
  margin-bottom: 3rem;
}

.my-10 {
  margin-top: 3rem;
}

.mb-24,
.my-24 {
  margin-bottom: 6rem;
}

.my-24 {
  margin-top: 6rem;
}

.opacity-50 {
  opacity: 50%;
}

.py-4-5 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}

.text-sm {
  font-size: 0.875rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-black {
  color: #000;
}

.bg-gradient-ltr {
  background: linear-gradient(90deg, #095cde, #53c3f3);
}

.bg-light-primary {
  background-color: #ebf3ff;
  color: #002152;
}

.bg-light-secondary {
  background-color: #e6eaee;
  color: #181e24;
}

.bg-light-success {
  background-color: #d2ffe8;
  color: #00391c;
}

.bg-light-danger {
  background-color: #ffdede;
  color: #450000;
}

.bg-light-warning {
  background-color: #fffdd8;
  color: #3f3c00;
}

.bg-light-info {
  background-color: #e6fdff;
  color: #00474d;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.text-width-md {
  max-width: 450px;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

.text-gray-600 {
  color: #6c757d !important;
}

.btn-xl {
  padding: 1rem 2rem;
}

.icon-mid:before {
  vertical-align: middle;
}

/* CUSTOM STYLES */

.stats-icon .bi {
  width: auto !important;
  height: auto !important;
  line-height: 0;
}
#auth #auth-right {
  background-image: url("https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80") !important;
  height: 100%;
  background-position: left center !important;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.fade-over {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #284b72;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.right-wrap-c {
  padding: 10px 100px;
  position: relative;
  z-index: 5;
}
.right-wrap-c h1 {
  color: #1bd880;
  font-size: 70px;
  line-height: 70px;
  margin-bottom: 26px;
}
.right-wrap-c h4 {
  color: #fff;
  max-width: 520px;
  line-height: 1.5;
}
.children.ni-submenu .sidebar-item .sidebar-link:after {
  display: none !important;
}

.sidebar-menu .sidebar-item:has(a.sidebar-link.active) > a.sidebar-link {
  background-color: #435ebe !important;
  color: #fff !important;
}
.sidebar-menu .sidebar-item:has(a.sidebar-link.active) > a.sidebar-link i,
.sidebar-menu .sidebar-item > a.active i {
  color: #fff !important;
}

/* CUSTOM FILE UPLOADER UI */

.custom-file-uploader {
  cursor: pointer;
}
.custom-file-uploader .file-upload-preview > img {
  height: 110px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.custom-file-uploader .file-upload-label {
  color: #435ebe;
  font-weight: 700;
  text-decoration: underline;
}

.dropZone{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #fafafa;
  border-style:dotted;
  color: #494747;
  background-color: #e4e0e0;
}

.position-field-icon {
  top: 14px !important;
  transform: translateY(0) !important;
}
.Permission{
  color: #435EBE  ;
  text-decoration:underline;
  font-size: 16pt;
}
.dropzoneList{
  list-style-type: none;
  padding: 12px 0 0;
  margin: 0;
  width: 100%;
}

.dropzoneList li:last-child{ margin-bottom: 0;}