.fltr-clear-btn {
  border: none;
  background-color: transparent !important;
  outline: none !important;
  color: #6c757d;
  font-size: 14px;
  padding: 8px;
}
.custom-select-drop {
  appearance: none !important;
  background-color: #f7f7f7;
  border: none !important;
  border-radius: 8px;
  padding: 8px 34px 8px 14px;
  max-width: 136px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-image: url("../../assets/images/icons/down-filled-arrow.svg");
  color: #000 !important;
}
.table-action-btn.text-link > span {
  color: #435ebe;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}
